/* eslint-disable */

import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { MAP_URL } from 'common/Map/const'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'

const getSegmentType = (widgetParams) => {
  var result = widgetParams.filter(obj => {
    return obj.slug === 'spatial_scale'
  })
  return result[0].value
}

function SegmentationView(props) {
  const { widgetParameters, filter, showSegmentsLayer } = props

  const urlParams = {
    segmentation_type: getSegmentType(widgetParameters)
  }
  if (widgetParameters?.[0] &&  widgetParameters[0]?.value && widgetParameters[0].value !== 'Tous') {
    urlParams['classe_strategique__in'] = widgetParameters[0].value.replace(/ *\([^)]*\) */g, "")
  }
  if (widgetParameters?.[1].slug === 'lines') {
    urlParams['code_ligne__in'] = widgetParameters[1].value.replaceAll(', ', ',')
  }
  const infraParam = widgetParameters?.find(e => e.slug === 'periode_infra')
  if (infraParam) {
    urlParams['version'] = infraParam.value
  }
  
  return (
    <>
      <Source
        type="vector"
        url={`${MAP_URL}/chartis/v2/layer/usage_reseau_mtbf_segmentation/mvt/segment/?${new URLSearchParams({
          segmentation_type: getSegmentType(widgetParameters),
          ...(widgetParameters?.find(e => e.slug === 'periode_infra') && { version: widgetParameters.find(e => e.slug === 'periode_infra').value }),
        }).toString()}`}
        source-layer={OBJECTS_LAYERS.tivGeo}
      >
        <Layer
          id='segmentMainLayer'
          type='line'
          paint={{
            "line-color": '#c3beb4',
            "line-width": 4
          }}
          layout={{ visibility: showSegmentsLayer }}
          source-layer={'usage_reseau_mtbf_segmentation'}
        />
        <Layer
          id='segmentMainLayerHighlighted'
          type='line'
          paint={{
            "line-color": '#c3beb4',
            "line-width": 8
          }}
          layout={{ visibility: showSegmentsLayer }}
          source-layer={'usage_reseau_mtbf_segmentation'}
          filter={filter}
        />
      </Source>
      <Source
        type="vector"
        url={`${MAP_URL}/chartis/v2/layer/usage_reseau_mtbf_segmentation/mvt/segment/?${new URLSearchParams(urlParams).toString()}`}
        source-layer={OBJECTS_LAYERS.tivGeo}
      >
        <Layer
          id="lineLayer"
          type="line"
          paint={{
            "line-color": '#82be00',
            "line-width": 4
          }}
          layout={{ visibility: 'visible' }}
          source-layer={'usage_reseau_mtbf_segmentation'}
        />
        <Layer
        id="lineLayerHighlighted"
        type="line"
        paint={{
          "line-color": '#82be00',
          "line-width": 8
        }}
        layout={{ visibility: 'visible' }}
        source-layer={'usage_reseau_mtbf_segmentation'}
        filter={filter}
        />
      </Source>
    </>
  )
}

export default SegmentationView
