/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteWidget } from "reducers/app";
import axios from "axios";
import ReactMapGL, {
	Source,
	Layer,
	Popup as MapPopup,
} from "react-map-gl";
import mapStyleEmptyJSON from 'assets/mapstyles/style_osrd_empty.json'
import mapStyleBlankJSON from 'assets/mapstyles/style_osrd_blank.json'
import mapStyleLightJSON from 'assets/mapstyles/style_osrd_light.json'


import PRView from 'components/Map/PRView'
import Spinner from 'react-bootstrap/Spinner';
import { DEFAULT_VIEWPORT, MAP_URL } from 'common/Map/const'
import { transformRequest, secondsToDhms } from 'utils/helpers'

import { displayColorLegend } from "./utils";
import SegmentationView from "components/Map/SegmentationView";

function MapWidget(props) {
	const dispatch = useDispatch()
  const { infraPeriods } = useSelector((state) => state.app);
	const [loadingError, setLoadingError] = useState(false);
	const [showPopup, togglePopup] = useState(false)
	const [featureInfoHover, setFeatureInfoHover] = useState(undefined)
	const [widgetData, setWidgetData] = useState(undefined);
	const [showIncidentsLayer, setShowIncidentsLayer] = useState("none");
	const [showIncidents, setShowIncidents] = useState(false);
	const [loader, setLoader] = useState(false)
	const [hoverInfo, setHoverInfo] = useState(null);
	const [showPrsLayer, setShowPrsLayer] = useState("visible");
	const [showSegmentsLayer, setShowSegmentsLayer] = useState("visible");
	const [color, setColor] = useState({
		Q1: '#cd0037',
		Q2: '#0088ce',
		Q3: '#00B050',
		segment: '#82be00',
	});

	const [viewport, setViewport] = useState({
		...DEFAULT_VIEWPORT,
		transformRequest: (url, resourceType) => transformRequest(url, resourceType, MAP_URL),
	});

	const togglePRs = () => {
		if (showPrsLayer === "visible") {
			setShowPrsLayer("none");
		} else {
			setShowPrsLayer("visible");
		}
	};

	const toggleSegments = () => {
		if (showSegmentsLayer === "visible") {
			setShowSegmentsLayer("none");
		} else {
			setShowSegmentsLayer("visible");
		}
	};

	useEffect(() => {
		setLoader(true)
		setLoadingError(false)
		axios({
			method: 'get',
			url: props.url,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			}
		})
			.then(function (response) {
				setWidgetData(response.data)
				setShowIncidentsLayer("visible")
				setShowIncidents(true)
			})
			.catch(function (error) {
				setLoadingError(true)
			})
			.then(function () {
				setLoader(false)
			});

	}, []);


	const applyMapStyle = () => {
		var result = props.widget.parameters.filter(obj => {
			return obj.slug === 'map_background'
		})
		if (result[0].value === 'Sans fond') {
			return mapStyleBlankJSON
		} else if (result[0].value === 'Enrichi') {
			return mapStyleLightJSON
		} else return mapStyleEmptyJSON
	}

	function roundNumber(value) {
		return Math.round(value * 100) / 100
	}


	function popupContent() {
		if (featureInfoHover.features[0].layer.id === 'mtbfLayer' || featureInfoHover.features[0].layer.id === 'mtbfLayerStyleHighlighted') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>MTBF : {featureInfoHover?.features[0]?.properties?.MTBF}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Classe stratégique : {featureInfoHover?.features[0]?.properties?.classe_strategique}</strong></div>
						<div><strong>Longueur (km) : {roundNumber(`${featureInfoHover?.features[0]?.properties?.longueur}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Segment de début : {featureInfoHover?.features[0]?.properties?.libelle_seg_debut}</strong></div>
						<div><strong>Segment de fin : {featureInfoHover?.features[0]?.properties?.libelle_seg_fin}</strong></div>
						<div className="my-2"></div>
						<div><strong>TER / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TER}`)}</strong></div>
						<div><strong>TGV / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TGV}`)}</strong></div>
						<div><strong>IC / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IC}`)}</strong></div>
						<div><strong>IDF / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IDF}`)}</strong></div>
						<div><strong>Fret / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.fret}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Type ambition : {featureInfoHover?.features[0]?.properties?.type_ambition}</strong></div>
						<div><strong>Type flux : {featureInfoHover?.features[0]?.properties?.type_flux}</strong></div>
						<div><strong>Type traffic : {featureInfoHover?.features[0]?.properties?.type_traffic}</strong></div>
					</div>
				</>
			)
		} else if (featureInfoHover.features[0].layer.id === 'prMainLayer') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Libellé du PR : {featureInfoHover.features[0].properties.libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>CI : {featureInfoHover?.features[0]?.properties?.code_ci}</strong></div>
						<div><strong>CH : {featureInfoHover?.features[0]?.properties?.code_ch}</strong></div>
					</div>
				</>
			)
		} else if (featureInfoHover.features[0].layer.id === 'lineLayer' || featureInfoHover.features[0].layer.id === 'segmentMainLayerAll' || featureInfoHover.features[0].layer.id === 'lineLayerClass' || featureInfoHover.features[0].layer.id === 'lineLayerClassOnly') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Sans incident MTBF</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Classe stratégique : {featureInfoHover?.features[0]?.properties?.classe_strategique}</strong></div>
						<div><strong>Longueur (km) : {roundNumber(`${featureInfoHover?.features[0]?.properties?.longueur}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Segment de début : {featureInfoHover?.features[0]?.properties?.libelle_seg_debut}</strong></div>
						<div><strong>Segment de fin : {featureInfoHover?.features[0]?.properties?.libelle_seg_fin}</strong></div>
						<div className="my-2"></div>
						<div><strong>TER / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TER}`)}</strong></div>
						<div><strong>TGV / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TGV}`)}</strong></div>
						<div><strong>IC / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IC}`)}</strong></div>
						<div><strong>IDF / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IDF}`)}</strong></div>
						<div><strong>Fret / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.fret}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Type ambition : {featureInfoHover?.features[0]?.properties?.type_ambition}</strong></div>
						<div><strong>Type flux : {featureInfoHover?.features[0]?.properties?.type_flux}</strong></div>
						<div><strong>Type traffic : {featureInfoHover?.features[0]?.properties?.type_traffic}</strong></div>
					</div>
				</>
			)
		} else if (featureInfoHover.features[0].layer.id === 'segmentMainLayerHighlighted') {
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Classe stratégique : {featureInfoHover.features[0].properties.classe_strategique}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Longueur (km) : {roundNumber(`${featureInfoHover?.features[0]?.properties?.longueur}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Segment de début : {featureInfoHover?.features[0]?.properties?.libelle_seg_debut}</strong></div>
						<div><strong>Segment de fin : {featureInfoHover?.features[0]?.properties?.libelle_seg_fin}</strong></div>
						<div className="my-2"></div>
						<div><strong>TER / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TER}`)}</strong></div>
						<div><strong>TGV / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.TGV}`)}</strong></div>
						<div><strong>IC / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IC}`)}</strong></div>
						<div><strong>IDF / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.IDF}`)}</strong></div>
						<div><strong>Fret / jour : {roundNumber(`${featureInfoHover?.features[0]?.properties?.fret}`)}</strong></div>
						<div className="my-2"></div>
						<div><strong>Type ambition : {featureInfoHover?.features[0]?.properties?.type_ambition}</strong></div>
						<div><strong>Type flux : {featureInfoHover?.features[0]?.properties?.type_flux}</strong></div>
						<div><strong>Type traffic : {featureInfoHover?.features[0]?.properties?.type_trafic}</strong></div>
					</div>
				</>
			)
		}
	}

	const mtbfLayerStyle = {
		id: 'mtbfLayer',
		type: "line",
		paint: {
			'line-width': 4,
			"line-color": [
				"case",
				[
					"all",
					[
						"<=",
						["get", "metric"],
						widgetData?.scale[0],
					],
				],
				color.Q1,
				[
					"all",
					[
						">",
						["get", "metric"],
						widgetData?.scale[0],
					],
					[
						"<",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q2,
				[
					"all",
					[
						">=",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q3,
				"#c3beb4",
			],
		}
	};

	const mtbfLayerStyleHighlighted = {
		id: 'mtbfLayerStyleHighlighted',
		type: "line",
		paint: {
			'line-width': 7,
			"line-color": [
				"case",
				[
					"all",
					[
						"<=",
						["get", "metric"],
						widgetData?.scale[0],
					],
				],
				color.Q1,
				[
					"all",
					[
						">",
						["get", "metric"],
						widgetData?.scale[0],
					],
					[
						"<",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q2,
				[
					"all",
					[
						">=",
						["get", "metric"],
						widgetData?.scale[1],
					],
				],
				color.Q3,
				"#c3beb4",
			],
		}
	};

	const deleteWidgetFromDashboard = () => {
		dispatch(deleteWidget(props.dashboardId, props.widget.id));
	};

	const onFeatureClick = e => {
		if (e?.features?.length > 0) {
			setFeatureInfoHover(e)
			togglePopup(true)
		}
	}
	const selectedMtbfFeature = (hoverInfo && hoverInfo.hoveredMTBF) || [];
	const selectedSegmentFeature = (hoverInfo && hoverInfo.hoveredSegments) || []

	const filter = useMemo(() => {
		if (selectedMtbfFeature.length > 0) {
			return ['in', 'id', ...selectedMtbfFeature];
		}
		return ['in', 'id_segment', ...selectedSegmentFeature];
	}, [selectedMtbfFeature, selectedSegmentFeature]);

	const onHover = useCallback(event => {
		const hoveredSegments = event.features && event.features
			.filter(f => f.sourceLayer === 'usage_reseau_mtbf_segmentation' && f.properties?.id_segment)
			.map(f => f.properties.id_segment);
		const hoveredMTBF = event.features && event.features
			.filter(f => (f.layer.id = 'mtbfLayer' || f.layer.id === 'mtbfLayerStyleHighlighted') && f.properties?.id)
			.map(f => f.properties.id);

		setHoverInfo({
			hoveredMTBF: hoveredMTBF,
			hoveredSegments: hoveredSegments,
		});
	}, []);
	const infraVersion = props.widget.parameters.find(e => e.slug === 'periode_infra')?.value
	const referenceDate = infraPeriods.find(e => String(e.name) === String(infraVersion))?.reference_date

	return (
		<>
			<div style={{ width: '100%', height: '100%' }}>
				{loader && (<div className='loader-container'>
					<Spinner animation="border" variant="info" />
				</div>)}
				<ReactMapGL
					{...viewport}
					style={{ cursor: 'pointer' }}
					width="100%"
					height="100%"
					mapStyle={applyMapStyle()}
					onViewportChange={setViewport}
					onClick={onFeatureClick}
					onHover={onHover}
					attributionControl={false}
					clickRadius={2}
					interactiveLayerIds={['prMainLayer', 'mtbfLayer', 'mtbfLayerStyleHighlighted', 'lineLayer', 'segmentMainLayer', 'segmentMainLayerHighlighted']}
				>
					{showIncidents && (
						<Source type="geojson" data={widgetData?.data}>
							<Layer {...mtbfLayerStyle} layout={{ visibility: showIncidentsLayer }} beforeId="prMainLayer"/>
							<Layer {...mtbfLayerStyleHighlighted} layout={{ visibility: showIncidentsLayer }} filter={filter} beforeId="prMainLayer"/>
						</Source>
					)}

					<SegmentationView
						widgetParameters={props.widget.parameters}
						filter={filter}
						showSegmentsLayer={showSegmentsLayer}
					/>
					<PRView 
						showPrsLayer={showPrsLayer} 
						referenceDate={referenceDate}
					/>

					{showPopup && (<MapPopup
						longitude={featureInfoHover?.lngLat[0]}
						latitude={featureInfoHover?.lngLat[1]}
						closeButton={true}
						captureScroll={true}
						onClose={() => togglePopup(false)}
						className="mapboxgl-hover-custom-popup"
					>{popupContent()}</MapPopup>)}
				</ReactMapGL>
				<div className='legend-widgetmap flex-column'>
					{props.widget.parameters.map(param => (
						<div className="d-flex flex-wrap" key={param.name}>
							<div className="font-weight-bold text-capitalize">{param.name}:</div>
							<div className="text-capitalize ml-2">{param.value}</div>
						</div>
					))}
				</div>
				{displayColorLegend(props.widget.parameters, widgetData, color)}
				<div className="delete" id="delete">
					<button
						type="button"
						className="btn-rounded btn-rounded-white"
						onClick={() => deleteWidgetFromDashboard()}
					>
						<i className="icons-close" aria-hidden="true" />
					</button>
				</div>
			</div>
			<div className="toggles" id="toggles">
				<button
					type="button"
					className={`mt-4 btn btn-sm ml-3 ${showPrsLayer === "visible" ? "btn-primary" : "btn-light"
						}`}
					onClick={togglePRs}
				>
					PR
				</button>
				{props.widget.parameters[0].value !== 'Tous' || props.widget.parameters[1].slug === 'lines' ? <button
					type="button"
					className={`mt-4 btn btn-sm ml-3 ${showSegmentsLayer === "visible" ? "btn-primary" : "btn-light"
						}`}
					onClick={toggleSegments}
				>
					SEGMENTS
				</button> : undefined}

			</div>
			{loadingError && (
				<div className="dataLoader" id="dataLoader">
					<div className="mx-5">Erreur lors du chargement des données, tentez de rafraîchir votre page ou supprimez ce widget</div>
				</div>
			)}
		</>
	)
}

export default MapWidget
