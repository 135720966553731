import React from 'react'
import { Source, Layer } from 'react-map-gl'
import { MAP_URL } from 'common/Map/const'
import { armenTronconLayer } from 'common/Map/Layers/geographiclayers.json'

function ArmenTronconView() {
  return (
    <>
      <Source
        type="vector"
        tiles={[`${MAP_URL}/armen/v2/lrs/mvt/troncon/?tile={z}/{x}/{y}`]}
      >
        <Layer
          {...armenTronconLayer}
          layout={{ visibility: 'visible' }}
          source-layer="default"
          id="armentroncon"
        />
      </Source>
      <div id="state-legend" className="legend">
        {armenTronconLayer.legend.map(l => (
          <div
            key={l.category}
          >
            <span
              style={{ backgroundColor: l.color }}
            />
            cat.
            {l.category}
          </div>
        ))}
      </div>
    </>
  )
}

export default ArmenTronconView
