/* eslint-disable */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardList, deleteDashboard, setActiveTab, setTabName, setDashboardId, setDashboardIsDefault, deleteWidgetsList, getInfraVersions } from "reducers/app";
import { updateLocation } from 'reducers/app'
import moment from 'moment'
import { MdDeleteForever } from "react-icons/md";
import Alert from "react-bootstrap/Alert";
import {
  Button, Card, Table
} from 'react-bootstrap'
import './Dashboard.scss'

const Dashboard = () => {
  const dispatch = useDispatch();
  const { showLoader, dashboards, app } = useSelector((state) => state.app);
  const { appPermissions } = useSelector(state => state.user)

  function isRoot() {
    return appPermissions.includes('usage-reseau::ROOT')
  }
  useEffect(() => {
    dispatch(getDashboardList());
    dispatch(getInfraVersions());
    dispatch(updateLocation('MTBF'));
  }, []);

  const handleDeleteDashboard = (id) => {
    dispatch(deleteDashboard(id));
    setTimeout(() => {
      dispatch(getDashboardList());
    }, 200);
  };

  const handleChangeTab = (name, id, defaultType) => {
    dispatch(setTabName(name));
    dispatch(setActiveTab(name));
    dispatch(setDashboardId(id))
    dispatch(setDashboardIsDefault(defaultType))
  };
  const handleChangeTabCreate = (name) => {
    dispatch(setTabName(name));
    dispatch(setActiveTab(name));
    dispatch(setDashboardId(''))
    dispatch(deleteWidgetsList())
    dispatch(setDashboardIsDefault(false))
  };

  const listNotDefaultDashboards = (d) => {
    if (d.default === false) {
      return (<tr key={d.id}>
        <td className="action-cursor-pointer" onClick={(k) => handleChangeTab(d.name, d.id, d.default)}>{d.name}</td>
        <td>{`${moment(d.last_modified).fromNow()}`}</td>
        <td>{`${moment(d.creation_date).format('DD MMMM YYYY')}`}</td>
        <td className="action-cursor-pointer icon-delete" onClick={() =>
          handleDeleteDashboard(d.id)
        }><MdDeleteForever /></td>
      </tr>)
    }
  }

  const listDefaultDashboards = (d) => {
    if (d.default === true) {
      return (<Card className="card-mtbf text-left mr-4" style={{ width: '18rem' }} key={d.id} >
        <Card.Body className="d-flex flex-column">
          <Card.Title>{d.name}</Card.Title>
          <Card.Text className='text-muted font-weight-light'>
            {d.description}
          </Card.Text>
          <Button className="mt-auto" variant="primary" size="sm" onClick={(k) => handleChangeTab(d.name, d.id, d.default)}>Visualiser ce modèle</Button>
          {isRoot() && <div className="action-cursor-pointer icon-delete-default" onClick={() =>
          handleDeleteDashboard(d.id)
        }><MdDeleteForever /></div>}
        </Card.Body>
      </Card>)
    }
  }

  return (
    <>
      {showLoader && <Alert className="alert-loading" variant="secondary">
        Chargement en cours ...
      </Alert>}
      <div className='m-4 pt-5'>
        <div className='mb-4 title'>Tableaux de bord par defaut</div>
        <div className="d-flex">
          {dashboards.map(d => (
            listDefaultDashboards(d)
          ))}
          <Card className="card-mtbf ml-4 text-left bg-secondary" style={{ width: '0.3rem' }} >
          </Card>
          <Card className="card-mtbf text-left ml-5" style={{ width: '18rem' }} >
            <Card.Body className="d-flex flex-column">
              <Card.Title>Créer un tableau de bord</Card.Title>
              <Card.Text className='text-muted font-weight-light'>
                Un dashboard et des widgets selon vos besoins
              </Card.Text>
              <Button className="mt-auto text-white" variant="success" size="sm" onClick={(k) => handleChangeTabCreate('Non sauvegardé')}>Créer</Button>
              
            </Card.Body>
          </Card>
        </div>

        <Table hover borderless className="table-mtbf mt-5">
          <thead>
            <tr>
              <th>Mes tableaux de bord</th>
              <th>Dernière modification</th>
              <th>Date de création</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dashboards.map(d => (
              listNotDefaultDashboards(d)
            ))}
          </tbody>
        </Table>
      </div>

    </>
  )
}

export default Dashboard
