/* eslint-disable */
import { transformRequest, secondsToDhms } from 'utils/helpers'

export const displayColorLegend = (parameters, widgetData, color) => {
	var result = parameters.filter(obj => {
		return obj.slug === 'classe_compare'
	})
	if (result[0].value === 'false') {
		return (<div className='legend-widgetmap-color flex-column'>
			<div>
				<span style={{ backgroundColor: color.Q1 }} />
				MTBF {'<'} {secondsToDhms(widgetData?.scale[0] * 60)}
			</div>
			<div>
				<span style={{ backgroundColor: color.Q2 }} />
				{secondsToDhms(widgetData?.scale[0] * 60)} {'<'} MTBF {'<'} {secondsToDhms(widgetData?.scale[1] * 60)}
			</div>
			<div>
				<span style={{ backgroundColor: color.Q3 }} />
				{secondsToDhms(widgetData?.scale[1] * 60)} {'<'} MTBF
			</div>
			<div>
				<span style={{ backgroundColor: color.segment }} />
				Sans incident
			</div>
		</div>)
	}
}