/* eslint-disable */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import nextId from 'react-id-generator'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'

import './DatePickerSNCF.css'
import moment from 'moment'

export const PICKER_MODES = {
  single: 'single',
  range: 'range',
  multiple: 'multiple',
}

const DatePickerSNCF = ({
  mode, date, onChange, placeholder, dateFormat, label, enableTime, onClear: onClearProps, disabled, minDate, maxDate,
}) => {
  const fpRef = useRef()
  const openFp = () => fpRef.current.flatpickr.toggle()

  const onClear = () => {
    fpRef.current.flatpickr.clear()
    onClearProps()
  }

  const renderClearButton = () => {
    if (date !== undefined) {
      return (
        <button type="button" className="btn-clear btn-primary" onClick={onClear}>
          <span className="sr-only">Supprimer la date</span>
          <i className="icons-open" aria-hidden="true" />
        </button>
      )
    }
    return null
  }

  const onOpen = () => {
    const fp = fpRef.current?.flatpickr
    if (fp && minDate && moment(minDate).year() < moment().year()) {
      const minDateMonth = moment(minDate).get('month')
      const minDateYear = moment(minDate).get('year')
      fp.changeMonth(minDateMonth ,false)
      fp.changeYear(minDateYear)
    }
  }

  const flatpickrOptions = {
    locale: French,
    dateFormat,
    mode,
    enableTime,
    minDate: minDate,
    maxDate: maxDate,
  }

  return (
    <div aria-hidden="true"  className="mb-3">
      <label htmlFor="range" className="font-weight-medium mb-2">{label}</label>
      <div className="flatpickr-wrapper">
        <div data-component="picker" data-mode="range" data-enable-time={enableTime} data-increment-hours-on-minutes-max={enableTime}>
          <div className="input-group" data-toggle>
            <div className="form-control-container">
              <Flatpickr
                ref={fpRef}
                id={`range${nextId()}`}
                tabIndex={-1}
                onChange={newDate => { onChange(newDate) }}
                onOpen={onOpen}
                options={flatpickrOptions}
                className="form-control no-pointer-events clear-option"
                placeholder={placeholder}               
                disabled={disabled}
              />
              <span className="form-control-state" />
            </div>
            <div className="input-group-append">
              <button
                tabIndex={-1}
                type="button"
                className="btn btn-primary btn-only-icon"
                onClick={openFp}
                disabled={disabled}
              >
                <i className="icons-calendar" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DatePickerSNCF.propTypes = {
  mode: PropTypes.oneOf(Object.keys(PICKER_MODES)),
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  enableTime: PropTypes.bool,
}

DatePickerSNCF.defaultProps = {
  mode: 'single',
  dateFormat: 'd/m/Y',
  label: 'Date',
  enableTime: false,
}

export default DatePickerSNCF
