/* eslint-disable */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { VictoryChart, VictoryBoxPlot, VictoryAxis, VictoryZoomContainer, VictoryLegend, LineSegment } from 'victory';
import { deleteWidget } from "reducers/app";
import Spinner from 'react-bootstrap/Spinner';
import { secondsToDhms, yScaleFormat } from 'utils/helpers'

function BoxplotWidget(props) {
	const dispatch = useDispatch();
	const [loadingError, setLoadingError] = useState(false);
	const [widgetData, setWidgetData] = useState([]);
	const { dashboardId } = useSelector(state => state.app)
	const [loader, setLoader] = useState(false)
	const [hovered, setHovered] = useState(false);
	const [labelText, setLabelText] = useState('');
	const [labelValue, setLabelValue] = useState(0);
	const [labelPositionX, setLabelPositionX] = useState(0)
	const [labelPositionY, setLabelPositionY] = useState(0)

	useEffect(() => {
		setLoader(true)
		setLoadingError(false)
		axios({
			method: 'get',
			url: props.url,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			}
		})
			.then(function (response) {
				setWidgetData(response.data)

			})
			.catch(function (error) {
				setLoadingError(true)
			})
			.then(function () {
				setLoader(false)
			});

	}, []);
	const deleteWidgetFromDashboard = (params) => {
		dispatch(deleteWidget(dashboardId, params));
	};

	return (
		<>
			{loader && (<div className='loader-container'>
				<Spinner animation="border" variant="info" />
			</div>)}
			<VictoryChart
				domainPadding={15}
				containerComponent={
					<VictoryZoomContainer
						zoomDimension="y"
					/>
				}
				animate={{
					duration: 2000,
					onLoad: { duration: 1000 }
				}}
			>
				<VictoryLegend x={125} y={0}
					title="Utilisez la molette de votre souris pour adapter l'échelle du graphique"
					orientation="horizontal"
					gutter={20}
					style={{ title: { fontSize: 6, color: "#747678" }, labels: { fill: "navy", fontSize: 10 } }}
					data={[
						{ name: "Q1", symbol: { fill: "tomato", type: "square" } },
						{ name: "Médiane", symbol: { fill: "#0088ce", type: "square" } },
						{ name: "Q3", symbol: { fill: "orange", type: "square" } }
					]}
				/>
				<VictoryAxis dependentAxis

					tickFormat={(x) => (yScaleFormat(x))}
					style={{ tickLabels: { fontSize: 6, padding: 2 } }}
				/>
				<VictoryAxis crossAxis
					tickFormat={tick => (tick >= 0 ? tick : `${tick.replace(/ *\([^)]*\) */g, "")}`)}
					style={{ tickLabels: { angle: -45, fontSize: 6, transform: 'translate(-20, 15)', padding: 3 } }}
				/>
				<VictoryBoxPlot
					boxWidth={15}
					data={widgetData}
					style={{
						min: { stroke: "tomato", strokeWidth: 3 },
						max: { stroke: "orange", strokeWidth: 3 },
						q1: { fill: "tomato" },
						q3: { fill: "orange" },
						median: { stroke: "#0088ce", strokeWidth: 4 },
						minLabels: { fill: "tomato" },
						maxLabels: { fill: "orange" }
					}}

					events={[{
						target: "median",
						eventHandlers: {
							onMouseOver: (e) => {
								setHovered(true)
								setLabelPositionX(e.nativeEvent.offsetX)
								setLabelPositionY(e.nativeEvent.offsetY - 40)
								return [{
									target: "median",
									mutation: (props) => {
										setLabelText('Médiane')
										setLabelValue(props.datum.median)
									}
								}]
							},
							onMouseLeave: () => {
								setHovered(false)
							}
						}
					},
					{
						target: "q1",
						eventHandlers: {
							onMouseOver: (e) => {
								setHovered(true)
								setLabelPositionX(e.nativeEvent.offsetX)
								setLabelPositionY(e.nativeEvent.offsetY - 40)
								return [{
									target: "q1",
									mutation: (props) => {
										setLabelText('Q1')
										setLabelValue(props.datum.q1)
									}
								}]
							},
							onMouseLeave: () => {
								setHovered(false)
							}
						}
					},
					{
						target: "q3",
						eventHandlers: {
							onMouseOver: (e) => {
								setHovered(true)
								setLabelPositionX(e.nativeEvent.offsetX)
								setLabelPositionY(e.nativeEvent.offsetY - 40)
								return [{
									target: "q3",
									mutation: (props) => {
										setLabelText('Q3')
										setLabelValue(props.datum.q3)
									}
								}]
							},
							onMouseLeave: () => {
								setHovered(false)
							}
						}
					},
					{
						target: "max",
						eventHandlers: {
							onMouseOver: (e) => {
								setHovered(true)
								setLabelPositionX(e.nativeEvent.offsetX)
								setLabelPositionY(e.nativeEvent.offsetY - 40)
								return [{
									target: "max",
									mutation: (props) => {
										setLabelText('Max')
										setLabelValue(props.datum.max)
									}
								}]
							},
							onMouseLeave: () => {
								setHovered(false)
							}
						}
					},
					{
						target: "min",
						eventHandlers: {
							onMouseOver: (e) => {
								setHovered(true)
								setLabelPositionX(e.nativeEvent.offsetX)
								setLabelPositionY(e.nativeEvent.offsetY - 40)
								return [{
									target: "min",
									mutation: (props) => {
										setLabelText('Min')
										setLabelValue(props.datum.min)
									}
								}]
							},
							onMouseLeave: () => {
								setHovered(false)
							}
						}
					}
					]}
				/>
			</VictoryChart>
			<div className='legend-widgetmap flex-column'>
					{props.widget.parameters.map(param => (
						<div className="d-flex" key={param.name}>
							<div className="font-weight-bold text-capitalize">{param.name}:</div>
							<div className="text-capitalize ml-2">{param.value}</div>
						</div>
					))}
				</div>
			<div className="delete" id="delete">
				<button
					type="button"
					className="btn-rounded btn-rounded-white"
					onClick={() => deleteWidgetFromDashboard(props.widget.id)}
				>
					<i className="icons-close" aria-hidden="true" />
				</button>
			</div>
			{hovered &&
				<div className="testlabel" style={{ left: labelPositionX, top: labelPositionY }}>
					{labelText} : {secondsToDhms(labelValue * 60)}
				</div>}
			{loadingError && (
				<div className="dataLoader" id="dataLoader">
					<div className="mx-5">Erreur lors du chargement des données, tentez de rafraîchir votre page ou supprimez ce widget</div>
				</div>
			)}
		</>
	);
}

export default BoxplotWidget;