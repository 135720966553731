import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from 'reducers/app'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DashboardNew from 'applications/MTBF/views/DashboardNew'
import iconHome from 'assets/icons/home.svg'
import Dashboard from './Dashboard'

function Home() {
  const dispatch = useDispatch()
  const { tabName, activeTab } = useSelector(state => state.app)
  const handleChangeTab = name => {
    dispatch(setActiveTab(name))
  }

  return (
    <>
      <main className="mastcontainer pl-0">
        <Tabs activeKey={activeTab} onSelect={k => handleChangeTab(k)} id="uncontrolled-tab-example" className="mb-3 navigation-tab" unmountOnExit>
          <Tab eventKey="home" title={<img src={iconHome} width="20" alt="" />}>
            <Dashboard />
          </Tab>
          <Tab eventKey={tabName} title={tabName}>
            <DashboardNew />
          </Tab>
        </Tabs>
      </main>
    </>
  )
}

export default Home
