export const API_URLS = {
  zones: '/mistral/zones/',
  dashboards: '/mistral/dashboards/',
  itineraire: '/mistral/itineraires/',
  circulation: '/mistral/circulations/',
}

export const METRICS = {
  itineraire: [
    {
      id: 'nb_circulations',
      label: 'Nombre de circulations',
    },
    {
      id: 'nb_itineraires_circules',
      label: 'Itineraires circulés/non circulés',
    },
    {
      id: 'temps_parcourcs_moyen',
      label: 'Temps de parcours moyen',
    },
    {
      id: 'temps_degagement_moyen',
      label: 'Temps de dégagement moyen',
    },
    {
      id: 'detruit_manuellement',
      label: 'Itinéraires détuits manuellement',
    },
    {
      id: 'daily_average',
      label: 'Moyenne de circulations',
    },
  ],
  circulation: [
    {
      id: 'count',
      label: 'Nombre de circulations ',
    },
    {
      id: 'average',
      label: 'Moyenne de circulations',
    },
  ],
}

export const TrainsTypes = [
  'international_voyageurs_grandeligne',
  'international_voyageurs_natio',
  'national_fret_type4',
  'national_fret_type5',
  'national_fret_type6',
  'national_fret_type7',
  'national_fret_type8',
  'national_voyageurs_grandeligne',
  'national_voyageurs_natio',
  'national_voyageurs_rer',
  'national_voyageurs_special',
  'national_voyageurs_ter',
  'unknown_voyageurs_grandeligne',
  'unknown_fret_unknown',
]

export const defaultDashboards = {
  itineraire: [
    {
      ID_dashboard: '1',
      name: 'Vue de synthèse',
      icon: 'https://image.flaticon.com/icons/png/128/944/944053.png',
      isDefault: true,
    },
    {
      ID_dashboard: '2',
      name: 'Détails itinéraires',
      icon: 'https://icons.veryicon.com/png/o/education-technology/smart-campus-1/view-details-2.png',
      isDefault: true,
    },
    {
      ID_dashboard: '3',
      name: 'Usage itinéraires',
      icon: 'https://icon-library.com/images/clock-icon/clock-icon-0.jpg',
      isDefault: true,
    },
  ],
  circulation: [
    {
      ID_dashboard: '1',
      name: 'Vue de synthèse',
      icon: 'https://image.flaticon.com/icons/png/128/944/944053.png',
      isDefault: true,
    },
    {
      ID_dashboard: '2',
      name: 'Details circulation',
      icon: 'https://icons.veryicon.com/png/o/education-technology/smart-campus-1/view-details-2.png',
      isDefault: true,
    },
  ],
}

export const defaultWidgets = {
  itineraire: [
    {
      dashboard: '1',
      ID_widget: '1',
      is_default: true,
      name: 'Nombre de circulations par itinéraire',
      metric: 'nb_circulations',
      representation_type: 'hbar',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_off',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 0,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '1',
      ID_widget: '2',
      is_default: true,
      name: 'Nombre d\'itinéraires circulés Vs itinéraires jamais circulés',
      metric: 'nb_itineraires_circules',
      representation_type: 'piechart',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_off',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 0,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '1',
      ID_widget: '3',
      is_default: true,
      name: 'Itinéraires détruits manuellement',
      metric: 'detruit_manuellement',
      representation_type: 'hbar',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_off',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 2,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '2',
      ID_widget: '1',
      is_default: true,
      name: 'Nombre de circulations par itinéraire par type de train',
      metric: 'nb_circulations',
      representation_type: 'hbar',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_on',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 0,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '2',
      ID_widget: '2',
      is_default: true,
      name: 'Moyenne de circulations par itineraire par type de train par jour',
      metric: 'daily_average',
      representation_type: 'line',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_on',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 2,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '3',
      ID_widget: '1',
      is_default: true,
      name: 'Temps de parcours moyen en seconde par itinéraire',
      metric: 'temps_parcourcs_moyen',
      representation_type: 'hbar',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_off',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 0,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '3',
      ID_widget: '2',
      is_default: true,
      name: 'Temps de dégagement moyen en seconde par itinéraire',
      metric: 'temps_degagement_moyen',
      representation_type: 'hbar',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_off',
      frequency: '',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 2,
        minH: 2,
        minW: 2,
      },
    },
  ],
  circulation: [
    {
      dashboard: '1',
      ID_widget: '1',
      is_default: true,
      name: 'Nombre de circulations par type de train par jour',
      metric: 'count',
      representation_type: 'hbar',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_on',
      frequency: 'daily',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 0,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '1',
      ID_widget: '2',
      is_default: true,
      name: 'Nombre de circulations par type de train',
      metric: 'count',
      representation_type: 'piechart',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: 'filter_on',
      frequency: 'all',
      layout_position: {
        w: 6,
        h: 2,
        x: 0,
        y: 2,
        minH: 2,
        minW: 2,
      },
    },
    {
      dashboard: '2',
      ID_widget: '1',
      is_default: true,
      name: 'Moyenne de circulations par jour par heure',
      metric: 'average',
      representation_type: 'line',
      start_date: '2019-01-01',
      end_date: '2019-01-31',
      filtered_objects: [],
      train_type: [],
      by_train_type: '',
      frequency: 'hourly',
      layout_position: {
        w: 6,
        h: 3,
        x: 0,
        y: 0,
        minH: 2,
        minW: 2,
      },
    },
  ],
}

export const buttomAxiosLegend = {
  itineraire: {
    nb_circulations: 'Nombre de circulations',
    temps_parcourcs_moyen: 'Temps de parcours moyen',
    temps_degagement_moyen: 'Temps de dégagement moyen',
    detruit_manuellement: 'Nombre de destructions',
  },
  circulation: {
    count: 'Nombre de circulations',
  },
}

export const weekDays = [
  {
    id: 0,
    label: 'Lundi',
  },
  {
    id: 1,
    label: 'Mardi',
  },
  {
    id: 2,
    label: 'Mercredi',
  },
  {
    id: 3,
    label: 'Jeudi',
  },
  {
    id: 4,
    label: 'Vendredi',
  },
  {
    id: 5,
    label: 'Samedi',
  },
  {
    id: 6,
    label: 'Dimanche',
  },
]
