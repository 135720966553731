/* eslint-disable */

import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { MAP_URL } from 'common/Map/const'
import { prMainLayer } from 'common/Map/Layers/geographiclayers.json'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'
import moment from 'moment'

function PRView(props) {
  let url = `${MAP_URL}/chartis/v2/layer/cassini_v2_gaia_point_remarquable/mvt/full_rgi_line_geo_centroid/`
  if (props.referenceDate) {
    const date = moment(props.referenceDate).format('YYYY-MM-DD')
    url = `${url}?date_debut_activite__lte_or_null=${date}&date_fin_activite__gte_or_null=${date}`
  }
  return (
    <Source
      type="vector"
      url={url}
      source-layer={OBJECTS_LAYERS.pmSigPr}
    >
      <Layer
        {...prMainLayer}
        layout={{ visibility: props.showPrsLayer }}
        source-layer={OBJECTS_LAYERS.pmSigPr}
				id="prMainLayer"
      />
      
    </Source>
  )
}

export default PRView
