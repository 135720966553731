/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToDashboard, getAvailableWidgets } from "reducers/app";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from "moment";
import "moment/locale/fr";
import DatePickerSNCF, { PICKER_MODES } from "common/BootstrapSNCF/DatePickerSNCF/DatePickerSNCF";
import { AiOutlineFileAdd } from "react-icons/ai";
import { AiOutlineInfoCircle } from "react-icons/ai";
import './Widget.scss'

const AddWidgetModal = (props) => {
  const dispatch = useDispatch();
  const { availableWidgets, dashboardId, infraPeriods } = useSelector((state) => state.app);
  const [show, setShow] = useState(false);
  const [widgetTypeIndex, setWidgetTypeIndex] = useState('');
  const [widgetTypeSlug, setWidgetTypeSlug] = useState('');
  const [widgetParams, setWidgetParams] = useState([]);
  const [enumArray, setENumArray] = useState([])
  const [validatorArray, setvalidatorArray] = useState(4)
  const [validatorArray2, setvalidatorArray2] = useState(0)
  const [lineCodeError, setLineCodeError] = useState(false)
  const [hasInfraPeriod, setHasInfraPeriod] = useState(false)
  const [selectedInfraPeriod, setSelectedInfraPeriod] = useState(null)

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(getAvailableWidgets());
  }, []);

  useEffect(() => {
    if (availableWidgets?.[widgetTypeIndex]?.parameters?.find(e => e.slug === 'periode_infra'))
      setHasInfraPeriod(true)
    else
      setHasInfraPeriod(false)
      setSelectedInfraPeriod(null)
  }, [widgetTypeIndex]);

  const handleShow = () => {
    setvalidatorArray(4)
    setvalidatorArray2(0)
    setWidgetTypeIndex('')
    setENumArray([])
    setWidgetParams([])
    setShow(true)
  };

  const addWidget = () => {
    setShow(false);
    const params = {
      widget_slug: widgetTypeSlug,
      position: {
        h: 5,
        w: 6,
        x: 0,
        y: 0,
        minW: 3,
        minH: 2,
        moved: false,
        static: false,
      },
      parameters: widgetParams
    };

    dispatch(addWidgetToDashboard(dashboardId, params));
  };
  const onSelectParamTypeEnum = (event, params) => {
    const object = {
      "slug": params.slug,
      "value": params.values[event]
    }
    if (widgetParams.filter(e => e.slug === params.slug).length > 0) {
      for (var i = 0; i < widgetParams.length; i++) {
        if (widgetParams[i].slug === params.slug) {
          widgetParams[i].value = params.values[event]
          setvalidatorArray2(widgetParams.length)
        }
      }
    } else {
      widgetParams.push(object)
      setvalidatorArray2(widgetParams.length)
    }
  };

  const onSelectParamInfraPeriod = (value, params) => {
    const infraPeriod = infraPeriods.find(e => String(e.name) === String(value))
    setSelectedInfraPeriod(infraPeriod)
    const object = {
      "slug": params.slug,
      "value": value,
    }

    const slugsToFilter = ['periode_infra', 'start_date', 'end_date']
    const filteredWidgetParams = [...widgetParams].filter(e => !slugsToFilter.includes(e.slug))
    const newWidgetParams = [...filteredWidgetParams, object]

    setWidgetParams(newWidgetParams)
    setvalidatorArray2(newWidgetParams.length)
  }

  const onSelectParamTypeMulti = (event, params) => {
    if (enumArray.filter(e => e === event).length > 0) {

      for (var i = 0; i < enumArray.length; i++) {
        if (enumArray[i] === event) {
          enumArray.splice(i, 1);
          i--;
        }
      }
    } else {
      enumArray.push(event)
    }
    if (widgetParams.filter(e => e.slug === params.slug).length > 0) {
      for (var i = 0; i < widgetParams.length; i++) {
        if (widgetParams[i].slug === params.slug) {
          widgetParams[i].value = enumArray.join(',')
          setvalidatorArray2(widgetParams.length)
        }
      }
    } else {
      const object = {
        "slug": params.slug,
        "value": enumArray.join(',')
      }
      widgetParams.push(object)
      setvalidatorArray2(widgetParams.length)
    }
  };

  const onSelectParamTypeDate = (date, params) => {
    const object = {
      "slug": params.slug,
      "value": moment(date).format(moment.HTML5_FMT.DATE)
    }
    if (widgetParams.filter(e => e.slug === params.slug).length > 0) {
      for (var i = 0; i < widgetParams.length; i++) {
        if (widgetParams[i].slug === params.slug) {
          widgetParams[i].value = moment(date).format(moment.HTML5_FMT.DATE)
          setvalidatorArray2(widgetParams.length)

        }
      }
    } else {
      widgetParams.push(object)
      setvalidatorArray2(widgetParams.length)

    }
  }

  const onSelectParamTypeBool = (event, params) => {
    const object = {
      "slug": params.slug,
      "value": event
    }
    if (widgetParams.filter(e => e.slug === params.slug).length > 0) {
      for (var i = 0; i < widgetParams.length; i++) {
        if (widgetParams[i].slug === params.slug) {
          widgetParams[i].value = event
          setvalidatorArray2(widgetParams.length)

        }
      }
    } else {
      widgetParams.push(object)
      setvalidatorArray2(widgetParams.length)

    }
  }

  const onSelectPramaTypeText = (event, params) => {
    if (event.match(/^(\d{6},\s+)*\d{6}$/) != null) {
      setLineCodeError(false)
      const object = {
        "slug": params.slug,
        "value": event
      }
      if (widgetParams.filter(e => e.slug === params.slug).length > 0) {
        for (var i = 0; i < widgetParams.length; i++) {
          if (widgetParams[i].slug === params.slug) {
            widgetParams[i].value = event
            setvalidatorArray2(widgetParams.length)

          }
        }
      } else {
        widgetParams.push(object)
        setvalidatorArray2(widgetParams.length)
      }
    } else {
      setLineCodeError(true)
    }
  }

  const onSelectWidgetType = (index, availableWidgets) => {
    setvalidatorArray(availableWidgets[index].parameters.filter((v) => (v.required === true)).length)
    setWidgetTypeIndex(index)
    setWidgetTypeSlug(availableWidgets[index].slug)
  }

  const sortParams = (a, b) => {
    if (a.slug === 'map_background') return -3
    if (b.slug === 'map_background') return 3
    if (a.slug === 'periode_infra') return -2
    if (b.slug === 'periode_infra') return 2
    return 0
  }

  const paramsWidget = () => {
    return (
      <>
        {widgetTypeIndex && availableWidgets[widgetTypeIndex].parameters.toSorted(sortParams).map(params => (
          <Col sm={12} lg={6} xl={6} key={params.name}>
            {params.type === "date" && <DatePickerSNCF
              key={selectedInfraPeriod?.id} 
              mode={PICKER_MODES.single}
              date=""
              onChange={(date) => onSelectParamTypeDate(date[0], params)}
              placeholder={params.name}
              dateFormat="d/m/Y"
              label={params.name}
              disabled={hasInfraPeriod && !selectedInfraPeriod}
              minDate={selectedInfraPeriod?.start_date ? moment(selectedInfraPeriod.start_date).format('DD.MM.YYYY') : "31.12.2017"}
              maxDate={selectedInfraPeriod?.end_date ? moment(selectedInfraPeriod.end_date).format('DD.MM.YYYY') : moment().subtract(15, 'days').format('DD.MM.YYYY')}
              enableTime
            />}
            {params.type === "enum" && <Form.Group controlId="formGridState1">
              <Form.Label>{params.name}</Form.Label>
              <Form.Control
                as="select"
                defaultValue="default"
                onChange={(e) => onSelectParamTypeEnum(e.target.value, params)}
              >
                <option value="default" disabled>
                  Choisir...
                </option>
                {params && params.values.map((value, index) => (
                  <option value={index} key={value}>{value}</option>
                ))}
              </Form.Control>
            </Form.Group>}
            {params.type === "multi_choice_enum" && <Form.Group controlId="formGridState1">
              <Form.Label>{params.name}</Form.Label>
              {params && params.values.map((value, index) => (
                <Form.Check key={value} type="checkbox" className="form-check-multi-enum" label={value} onChange={() => onSelectParamTypeMulti(value, params)} />
              ))}


            </Form.Group>}
            {params.type === "bool" && <Form.Group controlId="formGridState1">
              <Form.Label>{params.name}</Form.Label>
              {params.name === 'Comparaison inter classe' &&
                <OverlayTrigger
                  placement={'right'}
                  overlay={
                    <Tooltip id="tooltip-right" className="widget-modal-tooltip">
                      <span className="mb-2">Ce paramètre permet de spécifier si l’on souhaite comparer les valeurs de l’indicateur MTBF d’un segment :</span>
                      <span>1. Aux MTBF des segments de la même classe stratégique → Sélectionner Oui</span>
                      <span>2. Aux MTBF de tous les segments stratégiques du réseau → Sélectionner Non</span>
                    </Tooltip>
                  }
                >
                  <AiOutlineInfoCircle className="ml-2" />
                </OverlayTrigger>

              }
              <Form.Control
                as="select"
                defaultValue="default"
                onChange={(e) => onSelectParamTypeBool(e.target.value, params)}
              >
                <option value="default" disabled>
                  Choisir...
                </option>
                <option value='true'>Oui</option>
                <option value='false'>Non</option>
              </Form.Control>
            </Form.Group>}
            {params.slug === "lines" && <Form.Group controlId="formGridState1">
              <Form.Label>{params.name} (optionnel)</Form.Label>
              <Form.Control type="text" autoComplete="off" placeholder="Séparés par une virgule suivie d'un espace : 001000, 830000"
                onChange={(e) => onSelectPramaTypeText(e.target.value, params)}
              />
              {lineCodeError && <Form.Text id="passwordHelpBlock" muted>
                Un code ligne doit comporter 6 chiffres, si plusieurs codes lignes, il faut les séparer par une virgule suivie d'un espace
              </Form.Text>}
            </Form.Group>}
            {params.slug === "periode_infra" && (
              <Form.Group controlId="formGridState1">
              <Form.Label>{availableWidgets[widgetTypeIndex].parameters.find(e => e.slug === 'periode_infra').name}</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="default"
                  onChange={(e) => onSelectParamInfraPeriod(e.target.value, availableWidgets[widgetTypeIndex].parameters.find(e => e.slug === 'periode_infra'))}
                >
                <option value="default" disabled>
                  Choisir...
                </option>
                {infraPeriods && infraPeriods.map((value) => (
                  <option value={value.name} key={value.id}>{value.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
            )}
          </Col>
        ))}
      </>)
  }

  const typeWidget = () => {
    return (
        <Col sm={12} lg={6} xl={6}>
          <Form.Group controlId="formGridState1">
            <Form.Label>Type de représentation</Form.Label>
            <Form.Control
              as="select"
              defaultValue="default"
              onChange={(e) => onSelectWidgetType(e.target.value, availableWidgets)}
            >
              <option value="default" disabled>
                Choisir...
              </option>
              {availableWidgets && availableWidgets.map((widgettype, index) => (
                <option value={index} key={index}>{widgettype.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
    )
  }
  return (
    <>
      <Button variant="secondary" size="lg" onClick={handleShow} >
        <AiOutlineFileAdd />
      </Button>
      <Modal
        show={show}
        onExited={handleClose}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>Paramètres du widget</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              {typeWidget()}
              {paramsWidget()}
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={addWidget}
            disabled={validatorArray >= (validatorArray2 + 1) || lineCodeError}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddWidgetModal;
