/* eslint-disable default-case */
import produce from 'immer'
import {
  get, post, patch, deleteRequest,
} from '@osrdata/app_core/dist/requests'

export const GET_ERROR = 'usagereseau/GET_ERROR'
export const GET_ERROR_MESSAGE = 'usagereseau/GET_ERROR_MESSAGE'

export const SHOW_LOADER = 'SHOW_LOADER'
export const LOCATION = 'user/LOCATION'

export const GET_WIDGET_DATA = 'usagereseau/GET_WIDGET_DATA'
export const WIDGET_LIST = 'usagereseau/WIDGET_LIST'
export const POST_DASHBOARD = 'usagereseau/POST_DASHBOARD'
export const SET_DASHBOARD_ID = 'usagereseau/SET_DASHBOARD_ID'
export const GET_DASHBOARDS = 'usagereseau/GET_DASHBOARDS'
export const GET_INFRA_VERSIONS = 'usagereseau/GET_INFRA_VERSIONS'
export const GET_WIDGETS_LIST = 'usagereseau/GET_WIDGETS_LIST'
export const GET_AVAILABLE_WIDGETS = 'usagereseau/GET_AVAILABLE_WIDGETS'
export const POST_WIDGET = 'usagereseau/POST_WIDGET'
export const DELETE_WIDGET = 'usagereseau/DELETE_WIDGET'
export const DELETE_DASHBOARD = 'usagereseau/DELETE_DASHBOARD'
export const SET_TAB_NAME = 'usagereseau/SET_TAB_NAME'
export const SET_ACTIVE_TAB = 'usagereseau/SET_ACTIVE_TAB'
export const TAB_LIST = 'usagereseau/TAB_LIST'
export const GET_BOXPLOT_DATA = 'usagereseau/GET_BOXPLOT_DATA'
export const DASHBOARD_IS_DEFAULT = 'usagereseau/DASHBOARD_IS_DEFAULT'

const API_URLS = {
  incidents: '/usage_reseau/incidents/',
  dashboards: '/usage_reseau/dashboards/',
  availablewidgets: '/usage_reseau/available_widgets/',
  infraPeriods: '/usage_reseau/infrastructure_versions/',
}
const initialState = {
  error: false,
  errorMessage: '',
  showLoader: false,
  widgetData: {},
  widgetList: [],
  dashboard: {},
  dashboardId: '',
  dashboardIsDefault: false,
  dashboards: [],
  infraPeriods: [],
  widgetsList: [],
  widget: {},
  deletedWidget: '',
  deletedDashboard: '',
  tabName: '',
  activeTab: 'home',
  tabList: [],
  availableWidgets: null,
  boxplotData: [],
  location: '',
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ERROR:
        draft.error = action.error
        break
      case GET_ERROR_MESSAGE:
        draft.errorMessage = action.errorMessage
        break
      case GET_WIDGET_DATA:
        draft.widgetData = action.widgetData
        break
      case WIDGET_LIST:
        draft.widgetList = action.widgetList
        break
      case SHOW_LOADER:
        draft.showLoader = action.showLoader
        break
      case POST_DASHBOARD:
        draft.dashboard = action.dashboard
        break
      case SET_DASHBOARD_ID:
        draft.dashboardId = action.dashboardId
        break
      case GET_DASHBOARDS:
        draft.dashboards = action.dashboards
        break
      case GET_INFRA_VERSIONS:
        draft.infraPeriods = action.infraPeriods
        break
      case GET_WIDGETS_LIST:
        draft.widgetsList = action.widgetsList
        break
      case GET_AVAILABLE_WIDGETS:
        draft.availableWidgets = action.filtered
        break
      case POST_WIDGET:
        draft.widget = action.widget
        break
      case DELETE_WIDGET:
        draft.deletedWidget = action.deletedWidget
        break
      case DELETE_DASHBOARD:
        draft.deletedDashboard = action.deletedDashboard
        break
      case SET_TAB_NAME:
        draft.tabName = action.tabName
        break
      case SET_ACTIVE_TAB:
        draft.activeTab = action.activeTab
        break
      case TAB_LIST:
        draft.tabList = action.tabList
        break
      case GET_BOXPLOT_DATA:
        draft.boxplotData = action.boxplotData
        break
      case DASHBOARD_IS_DEFAULT:
        draft.dashboardIsDefault = action.dashboardIsDefault
        break
      case LOCATION:
        draft.location = action.location
        break
    }
  })
}

const errorManagement = error => {
  if (error.response.status === 403) {
    return {
      type: GET_ERROR_MESSAGE,
      errorMessage: 'Vous n‘avez pas l‘autorisation nécessaire pour effectuer cette action. Merci de contacter votre administrateur',
    }
  }
  return {
    type: GET_ERROR_MESSAGE,
    errorMessage: 'Une errreur inattendue est survenue. Merci de recharger la page ou contacter votre administrateur',
  }
}

export function updateLocation(location) {
  return {
    type: LOCATION,
    location,
  }
}

export const hideError = () => async dispatch => {
  dispatch({
    type: GET_ERROR,
    error: false,
  })
}

export const updateWidgetList = list => dispatch => {
  dispatch({
    type: WIDGET_LIST,
    widgetList: list,
  })
}
export const setDashboardIsDefault = bool => dispatch => {
  dispatch({
    type: DASHBOARD_IS_DEFAULT,
    dashboardIsDefault: bool,
  })
}

export const setTabName = name => dispatch => {
  dispatch({
    type: SET_TAB_NAME,
    tabName: name,
  })
}
export const setDashboardId = id => dispatch => {
  dispatch({
    type: SET_DASHBOARD_ID,
    dashboardId: id,
  })
}

export const deleteWidgetsList = () => async dispatch => {
  dispatch({
    type: GET_WIDGETS_LIST,
    widgetsList: [],
  })
}

export const setActiveTab = name => dispatch => {
  dispatch({
    type: SET_ACTIVE_TAB,
    activeTab: name,
  })
}

export const addTabList = tab => dispatch => {
  dispatch({
    type: TAB_LIST,
    activeTab: tab,
  })
}

export const postDashboard = params => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const dashboard = await post(API_URLS.dashboards, params)
    dispatch({
      type: POST_DASHBOARD,
      dashboard,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    dispatch({
      type: SET_DASHBOARD_ID,
      dashboardId: dashboard.id,
    })
    return dashboard
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const getDashboardList = () => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const { results: dashboards } = await get(API_URLS.dashboards)
    dispatch({
      type: GET_DASHBOARDS,
      dashboards,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return dashboards
  } catch (error) {
    dispatch(errorManagement(error))

    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const getInfraVersions = () => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const { results: infraPeriods } = await get(API_URLS.infraPeriods)
    dispatch({
      type: GET_INFRA_VERSIONS,
      infraPeriods,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return infraPeriods
  } catch (error) {
    dispatch(errorManagement(error))

    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const getWidgetsList = dashboardID => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const { widgets: widgetsList } = await get(
      `${API_URLS.dashboards}${dashboardID}/`,
    )
    dispatch({
      type: GET_WIDGETS_LIST,
      widgetsList,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return widgetsList
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const getAvailableWidgets = () => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const availableWidgets = await get(API_URLS.availablewidgets)
    const filtered = availableWidgets.filter(widget => widget.slug === 'mtbf_map' || widget.slug === 'mtbf_boxplot')
    dispatch({
      type: GET_AVAILABLE_WIDGETS,
      filtered,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return filtered
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const getWidgetData = queries => async dispatch => {
  try {
    dispatch({
      type: GET_ERROR,
      error: false,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const params = {
      spatial_scale: queries.spatial_scale,
      operation_type: queries.operation_type,
      start_time: queries.start_date,
      end_time: queries.end_date,
    }
    const { results: widgetData } = await get(API_URLS.incidents, params)
    dispatch({
      type: GET_WIDGET_DATA,
      widgetData,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return widgetData
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const patchWidget = (dashboardID, widgetID, layout) => async dispatch => {
  try {
    const params = {
      position: layout,
    }
    const widget = await patch(
      `${API_URLS.dashboards}${dashboardID}/widgets/${widgetID}/`,
      params,
    )

    return widget
  } catch (error) {
    dispatch(errorManagement(error))

    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const addWidgetToDashboard = (dashboardID, params) => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const widget = await post(
      `${API_URLS.dashboards}${dashboardID}/widgets/`,
      params,
    )
    dispatch({
      type: POST_WIDGET,
      widget,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return widget
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const deleteWidget = (dashboardID, widgetID) => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const deletedWidget = await deleteRequest(
      `${API_URLS.dashboards}${dashboardID}/widgets/${widgetID}/`,
    )
    dispatch({
      type: DELETE_WIDGET,
      deletedWidget: widgetID,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return deletedWidget
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const deleteDashboard = dashboardID => async dispatch => {
  try {
    const deletedDashboard = await deleteRequest(
      `${API_URLS.dashboards}${dashboardID}/`,
    )
    dispatch({
      type: DELETE_DASHBOARD,
      deletedDashboard: dashboardID,
    })
    return deletedDashboard
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}

export const getBoxplotData = url => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const boxplotData = await get(
      `${url}`,
    )
    dispatch({
      type: GET_BOXPLOT_DATA,
      boxplotData,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return boxplotData
  } catch (error) {
    dispatch(errorManagement(error))
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}
