/* eslint-disable */

import MAIN_API from "config/config"

/**
 * Fonction de callback lors d'un requête vers une URL extérieure
 * @param {string} url - L'URL de la requête
 * @param {string} resourceType - Le type de ressource
 * @param {string} urlmap - L'URL de base pour la carte
 * @returns {(object| null)} Le token d'authentifcation lorsqu'il est nécessaire
 */

export const normalizeString = str => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

/**
 * Filter an array of string by element's name or by string element itself, using a search value
 * @param {array} array - Array to filter
 * @param {string} search - Search term
 * @returns {array} Filtered array
 */
export function filterBySearch(array, search) {
  return array.filter(element => normalizeString(element[0]).includes(normalizeString(search)))
}

const isTileserverResource = (type) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url) => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

export const transformRequest = (url, resourceType, urlmap) => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}

export const getSignalLayerId = type => `signal_${type.toLowerCase().replace(/ |\./g, '_')}`

export function secondsToDhms(seconds) {
  seconds = Number(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const dDisplay = d > 0 ? d + (d === 1 ? 'j ' : 'j ') : ''
  const hDisplay = h > 0 ? h + (h === 1 ? 'h ' : 'h ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? 'min' : 'min') : ''
  return dDisplay + hDisplay + mDisplay
}

export function yScaleFormat(minutes) {
  const d = Math.floor(minutes / 1440)
  const h = Math.floor((minutes - (d * 1440)) / 60)
  const m = Math.round(minutes % 60)
  if (d > 3) {
    return (d + ' jours')
  } else if (d === 0) {
    return (h + 'h ' + m + 'm')
  }
  else if (d < 3) {
    return (d + 'j ' + h + 'h ' + m + 'm')
  }
}
