/* eslint-disable */

import React, { useEffect, useState } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"
import { useDispatch, useSelector } from "react-redux";
import { getWidgetsList, patchWidget, deleteWidgetsList } from "reducers/app";
import AddWidgetModal from "applications/MTBF/components/AddWidgetModal"
import AddDashboardModal from 'applications/MTBF/components/AddDashboardModal'
import BoxplotWidget from "../components/BoxplotWidget"
import Alert from "react-bootstrap/Alert";

import MapWidget from "../components/MapWidget"
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { AiFillUnlock, AiFillLock } from "react-icons/ai";

const ResponsiveGridLayout = WidthProvider(Responsive);

function Dashboard() {
  const dispatch = useDispatch();

  const [unlock, setLock] = useState(false);
  const { showLoader, widgetsList, dashboardId, widget, deletedWidget, dashboardIsDefault, tabName, activeTab } = useSelector((state) => state.app);
  const { appPermissions } = useSelector(state => state.user)


  useEffect(() => {
    if (dashboardId) {
      dispatch(getWidgetsList(dashboardId));
    }
  }, [widget, deletedWidget, tabName, activeTab]);

  useEffect(() => {
    dispatch(deleteWidgetsList());
  }, []);

  function isRoot() {
    return appPermissions.includes('usage-reseau::ROOT')
  }
  const params = {
    isDraggable: unlock,
    isResizable: unlock,
    resizeHandles: ["sw", "nw", "se", "ne"]
  };

  const onLayoutChange = (layout) => {
    if (!dashboardIsDefault) {
      layout.map((layout) =>
        dispatch(patchWidget(dashboardId, layout.i, layout))
      );
    }

  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Cliquer pour verrouiller/déverrouiller la position des widgets
    </Tooltip>
  );

  const displayAddDashboardButton = () => {
    if (!dashboardIsDefault) {
      return <AddDashboardModal />
    } else return <></>
  }
  const displayAddWidgetButton = () => {
    if ((dashboardIsDefault && isRoot() && dashboardId)) {
      return <AddWidgetModal />

    } else if (!dashboardIsDefault && dashboardId) {
      return <AddWidgetModal />

    } else return <></>
  }
  return (
    <>
      <div style={{ width: '100vw', paddingTop: '44px' }}>
      {showLoader && <Alert className="alert-loading" variant="secondary">
        Chargement en cours ...
      </Alert>}
        <ButtonGroup aria-label="Basic example" className="dashboard-action-button">
          {displayAddDashboardButton()}
          {displayAddWidgetButton()}
          <OverlayTrigger
            placement="right"
            delay={{ show: 600, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button variant={!unlock ? 'secondary' : 'secondary-outline border border-dark'} size="lg" onClick={(l) => setLock(!unlock)} >
              {unlock ? <AiFillUnlock /> : <AiFillLock />}
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
        <ResponsiveGridLayout
          style={{ paddingTop: '45px' }}
          className="layout"
          {...params}
          autoSize
          onLayoutChange={onLayoutChange}
          measureBeforeMount={true}
          useCSSTransforms={true}
        >
          {widgetsList && widgetsList.map(widget => (
            <div key={widget.id} className='widget-container' data-grid={widget.position} >
              {widget.widget_type === "map" ? <MapWidget url={widget.metric_url} widget={widget} dashboardId={dashboardId} unlock={unlock} /> : <BoxplotWidget url={widget.metric_url} widget={widget} />}
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </>
  );
}

export default Dashboard;