/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postDashboard, setTabName, setActiveTab } from 'reducers/app'
import { FaSave } from 'react-icons/fa'
import { Button, Col, Form, Modal } from 'react-bootstrap'

const AddDashboardModal = () => {
  const dispatch = useDispatch()
  const { appPermissions } = useSelector(state => state.user)
  const { dashboardId } = useSelector(state => state.app)

  const [show, setShow] = useState(false)
  const [dashboardName, setDashboardName] = useState('')
  const [dashboardDescription, setDashboardDescription] = useState('')
  const [dashboardDefault, setDashboardDefault] = useState(false)

  const handleClose = () => {
    setShow(false)
    setDashboardName('')
    setDashboardDescription('')
    setDashboardDefault(false)
  }

  useEffect(() => {
    if (dashboardId === '') {
      setShow(true);
    }
  }, []);

  function isRoot() {
    return appPermissions.includes('usage-reseau::ROOT')
  }

  const handleShow = () => setShow(true)

  const addDashboard = () => {
    const params = {
      name: dashboardName,
      default: dashboardDefault,
      description: dashboardDescription,
    }
    dispatch(postDashboard(params))
    dispatch(setTabName(dashboardName))
    dispatch(setActiveTab(dashboardName))
    setShow(false)
  }

  const handleNameChange = event => {
    setDashboardName(event.target.value)
  }

  const handleDefaultChange = event => {
    setDashboardDefault(event.target.checked)
  }

  const handleDescriptionChange = event => {
    setDashboardDescription(event.target.value)
  }

  return (
    <>
      <Button variant="secondary" size="lg" onClick={handleShow}>
        <FaSave />
      </Button>
      <Modal
        show={show}
        onExited={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>Paramètres du tableau de bord</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="formName">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control type="text" placeholder="Nom du tableau bord" onChange={handleNameChange} />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Description de votre tableau de bord" onChange={handleDescriptionChange} />
                </Form.Group>
              </Col>
            </Form.Row>
            {isRoot() && (
              <Form.Row>
                <Col>
                  <Form.Group controlId="formCheckbox">
                    <Form.Check onChange={handleDefaultChange} type="checkbox" label="Marquer comme tableau de bord par défaut" />
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={!dashboardName || !dashboardDescription}
            onClick={addDashboard}
          >
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddDashboardModal
